<template>
  <v-snackbar right top v-model="snackbar">
    {{ message }}
    <template v-slot:action="{ attrs }">
      <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="snackbar = false"
          small
          fab
      >
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>

export default {
  name: 'AlertBar',

  data: () => ({
    message: '',
    snackbar: false,
  }),

  methods: {
    open(msg) {
      this.message = msg
      this.snackbar = true
    },
  },
}
</script>
